<template>
  <div
    class="d-flex flex-column align-center"
    style="height: 100%; width: 100%"
  >
    <ExamReader @exam-id-detected="loadExam" />
  </div>
</template>

<script setup>
import ExamReader from "@/components/ExamReader.vue";
import router from "@/router";

async function loadExam(examId) {
  router.push({ name: "answersReaderView", params: { examId } });
}
</script>
