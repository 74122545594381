<template>
  <v-layout class="rounded rounded-md" style="min-height: 100vh !important">
    <!-- <v-navigation-drawer>
      <v-list>
        <v-list-item title="Navigation drawer"></v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-app-bar class="px-3" v-if="user"> Olá, {{ user.name }} </v-app-bar>
    <v-main
      class="d-flex flex-column align-center"
      style="min-height: 100% !important"
    >
      <router-view />
    </v-main>
  </v-layout>
</template>
<script setup>
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const user = ref(null);

onBeforeMount(() => {
  user.value = store.state.auth.user;
});
</script>
