// src/axios.js
import axios from "axios";

// Crie uma instância do Axios
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_NIO_API_V1_URL_BASE, // Substitua pela URL da sua API
  timeout: process.env.VUE_APP_REQ_TIMEOUT, // Tempo limite de 10 segundos
  headers: {
    "Content-Type": "application/json",
    // Adicione outros cabeçalhos aqui se necessário
  },
});

// Adicionar interceptores se necessário
axiosInstance.interceptors.request.use(
  (config) => {
    // Pode adicionar token de autenticação aqui se necessário
    // config.headers.Authorization = `${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Lidar com erros de resposta
    return Promise.reject(error);
  }
);
console.log(process.env.NIO_API_V1_URL_BASE);
export default axiosInstance;
