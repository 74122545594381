import securityService from "../../services/securityService";

const state = {
  user: null,
};

const getters = {
  userLogged: (state) => {
    return state.user && state.user.token;
  },
};

const mutations = {
  SET_USER: (state, user) => {
    console.log(user);
    state.user = user;
    window.sessionStorage.setItem("nio-exam-app-t", user.token);
  },
};

const actions = {
  async login({ commit }, credentials) {
    const resp = await securityService.login(credentials);
    await commit("SET_USER", resp.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
