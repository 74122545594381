<template>
  <div
    class="d-flex flex-column align-center justify-space-around pt-5"
    style="height: 100%"
  >
    <v-card width="300" elevation="0">
      <v-card-text class="text-center">
        Para iniciar uma correção, clique no botão abaixo e leia o QR Code da
        avaliação gerada pelo Nivelamento Online
      </v-card-text>
      <v-card-text class="d-flex align-center justify-center">
        <qrcode-stream
          v-if="showReader"
          @init="onCameraReady"
          @error="onError"
          @detect="onDetect"
        >
        </qrcode-stream>
        <v-img src="img/qrcode.png" contain v-else />
      </v-card-text>
    </v-card>

    <div class="d-flex flex-column align-center">
      <v-btn
        variant="outlined"
        size="large"
        block
        class=""
        :disabled="showReader || examLoading"
        @click="onShowQrCodeReader"
        >{{ getBtnLabel }}</v-btn
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits } from "vue";
import { QrcodeStream } from "vue-qrcode-reader";

const emit = defineEmits(["examIdDetected"]);

const showReader = ref(false);
const examLoading = ref(false);

const defaultConstraintOptions = [
  { label: "rear camera", constraints: { facingMode: "environment" } },
  { label: "front camera", constraints: { facingMode: "user" } },
];
const constraintOptions = ref(defaultConstraintOptions);

const getBtnLabel = computed(() => {
  if (showReader.value && !examLoading.value) {
    return "Lendo QR Code...";
  } else if (!showReader.value && examLoading.value) {
    return "Carregando avaliação...";
  } else {
    return "Iniciar Correção";
  }
});

//actions

const onShowQrCodeReader = () => (showReader.value = true);

const onDetect = (values) => {
  const onlyNumber = /^\d+$/;
  const examId = values[0].rawValue;

  if (onlyNumber.test(examId)) {
    showReader.value = false;
    examLoading.value = true;

    emit("examIdDetected", examId);
  }
};

async function onCameraReady() {
  // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
  // camera access permission. `QrcodeStream` internally takes care of
  // requesting the permissions. The `camera-on` event should guarantee that this
  // has happened.
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(({ kind }) => kind === "videoinput");

  constraintOptions.value = [
    ...defaultConstraintOptions,
    ...videoDevices.map(({ deviceId, label }) => ({
      label: `${label} (ID: ${deviceId})`,
      constraints: { deviceId },
    })),
  ];
}
const onError = (error) => {
  console.error("Error scanning QR code: ", error);
  alert(error);
};
</script>
