import httpClient from "../infra/config/httpClientV1";

const baseUrl = "security/authentication/";

const getToken = (token) => {
  return httpClient.post(baseUrl + "reconnect/", { t: token });
};

const login = async (data) => {
  return httpClient.post(baseUrl, data);
};

const logout = (token) => {
  return httpClient.post(baseUrl + "logout/", {
    t: token,
  });
};

const reconnect = async (token) => {
  return await httpClient.post(baseUrl + "reconnect/", {
    t: token,
  });
};

const isLogged = () => {
  const token = window.sessionStorage.getItem("nio-exam-app-t") || null;
  return !!token;
};

export default {
  getToken,
  login,
  logout,
  reconnect,
  isLogged,
};
