import ExamReaderView from "../views/ExamReaderView.vue";

export const routes = [
  {
    path: "/",
    name: "examReaderView",
    component: ExamReaderView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:examId/",
    name: "answersReaderView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AnswersReaderView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/poc",
    name: "poc",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/POC.vue"),
    // meta: { requiresAuth: false },
  },
];
